<template>
  <div class="c-legal">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CLegal'
}
</script>

<style lang="scss">
  .primary {
    color: $primary;
  }

  .c-legal {
    h3 {
      color: $primary;
    }

    h3 + h3, h3 + section {
      margin-top: 2em;
    }

    section {
      margin-bottom: 2em;
    }

    .warning {
      color: red;
    }

    .black {
      color: black;
    }

    .center {
      text-align: center;
    }

    ul.unstyled {
      list-style: none;
    }

    ol.alpha {
      list-style-type: lower-latin;
    }

    ul {
      margin-bottom: 2em;
    }

    li {
      margin-bottom: 1em;
    }

    p {
      margin-bottom: 2em;
    }
  }
</style>
