<template>
  <l-default>
    <OTitle>Terms Of Use</OTitle>
    <CShareThisFollow/>
    <CLegal>
      <div id="terms"></div>
    </CLegal>
  </l-default>
</template>

<script>
import LDefault from '../../layouts/LDefault'
import OTitle from '../../objects/OTitle'
import CLegal from '../../components/CLegal'
import CShareThisFollow from '../../components/CShareThisFollow'

export default {
  name: 'PageTerms',
  components: { CLegal, OTitle, LDefault, CShareThisFollow },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker | Terms',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call And Caller ID Faker - Terms'
      },
      {
        name: 'keywords',
        content: 'myphonerobot terms'
      }
    ]
  },
  mounted () {
    fetch('/static/terms.html')
      .then(response => response.text())
      .then(template => {
        document.getElementById('terms').innerHTML = template
      })
  }
}
</script>
